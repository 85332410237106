
    import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons-vue';
    import { message } from 'ant-design-vue';
    import axios from 'axios';
    import { Vue, Options } from 'vue-class-component';
    import infoComponentVue from '../components/info.component.vue';
    import { ref } from 'vue';

    interface FileItem {
        uid: string;
        name?: string;
        status?: string;
        response?: string;
        url?: string;
        preview?: string;
        originFileObj?: any;
        file: string | Blob;
    }

    @Options({
        components: {
            'info': infoComponentVue,
            'extension-table-outlined': OrderedListOutlined,
            'upload-outlined': UploadOutlined
        }
    })

    export default class BoxedPriceLock extends Vue {
        loading = true;
        fileList = ref<FileItem[]>([]);
        csvFile: any = null;
        csvFileLabel: any = '';
        loadingUpload = false;

        async uploadCsv() {
            try {
                const fd = new FormData();
                fd.append('file', this.csvFile);

                this.loadingUpload = true;
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/price-lock/upload`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                    }
                });
                const data = await res.data;
                this.loadingUpload = false;
                if (data.error) {
                    message.error(data.error_message);
                } else {
                    message.success('Successfully uploaded CSV File! Please check back shortly.');
                }
            } catch (error) {
                this.loadingUpload = false;
                console.log(error);
                message.error('Sorry, something went wrong. Please try again later.');
            }
        }

        beforeUpload = (file: FileItem) => {
            this.csvFile = file;
            this.csvFileLabel = file.name;
            return false;
        }

        mounted() {
            console.log('[APP] Dashboard Mounted');
        }
    }

